import {Injectable, OnInit} from '@angular/core';

import {Storage} from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnInit {
  private _storage: Storage

  constructor(
    private storage: Storage
  ) {
  }

  async ngOnInit() {
    this._storage = await this.storage.create();
  }

  set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  get(key) {
    return this._storage?.get(key);
  }

  remove(key) {
    this._storage?.remove(key)
  }
}

export class StorageServiceService {
}
