import {Injectable} from '@angular/core';
import axios from "../_helper/axios.helper";
import {AuthorizationService} from "./authorization.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private authorizationService: AuthorizationService
  ) {
  }

  async userAnnouncements(option) {
    try {
      return await axios.post('/api/userannouncements/getpaging', option,
        {
          headers:
            {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${this.authorizationService.getToken()}`
            }
        });
    } catch (e) {
      return e;
    }
  }

  async registerDevice(option) {
    try {
      return await axios.post('/api/userDevices/registerDevice', option,
        {
          headers:
            {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${this.authorizationService.getToken()}`
            }
        });
    } catch (e) {
      return e;
    }
  }

  async unRegisterDevice(id) {
    try {
      return await axios.post('/api/userDevices/unregisterDevice', {id: id},
        {
          headers:
            {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${this.authorizationService.getToken()}`
            }
        });
    } catch (e) {
      return e;
    }
  }
}
