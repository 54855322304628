import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnumsService {

  serviceContractPaymentStatus = []
  serviceContractType = []
  serviceContractStatus = []
  source = []
  gender = []
  stage = []
  status = []
  action = []
  documentType =[]

  constructor() {
  }

  setDocumentType(data) {
    this.documentType = data
  }

  getDocumentType() {
    return this.documentType
  }

  //
  setAction(data) {
    this.action = data
  }

  getAction() {
    return this.action
  }

  //
  setStatus(data) {
    this.status = data
  }

  getStatus() {
    return this.status
  }

  //
  setGender(data) {
    this.gender = data
  }

  getGender() {
    return this.gender
  }

  //
  setStage(data) {
    this.stage = data
  }

  getStage() {
    return this.stage
  }

  //
  setSource(data) {
    this.source = data
  }

  getSource() {
    return this.source
  }

  //
  setServiceContractPaymentStatus(data) {
    this.serviceContractPaymentStatus = data
  }

  getServiceContractPaymentStatus() {
    return this.serviceContractPaymentStatus
  }

  //
  setServiceContractType(data) {
    this.serviceContractType = data
  }

  getServiceContractType() {
    return this.serviceContractType
  }

  //
  setServiceContractStatus(data) {
    this.serviceContractStatus = data
  }

  getServiceContractStatus() {
    return this.serviceContractStatus
  }
}
