import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {UserService} from './user.service';
import axios from "../_helper/axios.helper";
import {StorageService} from "./storage-service.service";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private loginState = false;
  private token = '';

  role = ''
  roles = [
    {
      role: 'None',
      value: 0
    },
    {
      role: 'Consultant',
      value: 1
    },
    {
      role: 'Lawyer',
      value: 2
    },
    {
      role: 'Admin',
      value: 3
    }
  ]

  rolesActive = [
    {
      role: 'Lawyer',
      value: 2
    },
    {
      role: 'Admin',
      value: 3
    }
  ]

  rolesActive2 = [
    {
      role: 'Admin',
      value: 3
    },
    {
      role: 'Consultant',
      value: 1
    },
  ]

  constructor(
    private userService: UserService,
    private storageService: StorageService
  ) {
  }

  getLoginState() {
    return this.loginState;
  }

  setLoginState(state) {
    this.loginState = state;
  }

  async setToken(token: string) {
    if (token && token !== '' && token !== 'null') {
      this.token = token;
      const data = jwt_decode(token);
      // @ts-ignore
      this.role = data.Role + ''
      // @ts-ignore
      const user = {
        // @ts-ignore
        id: data.Id,
        // @ts-ignore
        name: data.Name,
        // @ts-ignore
        role: data.Role,
        // @ts-ignore
        avatar: data.Avatar
      };
      await this.userService.setUser(user);
      localStorage.setItem('at', token);
      this.storageService.set('at', token);
      this.setLoginState(true);
    }
  }

  getToken() {
    return this.token;
  }


  async getAllUser() {
    try {
      const res = await axios.post('/api/configuration/users',
        {
          freeTextSearch: '',
        }
        , {headers: {Authorization: `Bearer ${this.getToken()}`}})
      if (res.data && res.data.result) {
        return res.data.result;
      }
    } catch (e) {
      console.log(e)
      return []
    }
  }

  async getEnums() {
    try {
      const res = await axios.post('/api/configuration/enums',
        {}
        , {headers: {Authorization: `Bearer ${this.getToken()}`}})
      if (res.data && res.data.result) {
        return res.data.result;
      }
    } catch (e) {
      console.log(e)
      return []
    }
  }

  getActiveRole(rBox = 0) {
    let array = []
    if (rBox == 0) {
      array = this.rolesActive
    } else {
      array = this.rolesActive2
    }
    const r = this.role.toLowerCase() || '';
    for (let ro of array) {
      if (ro.role.toLowerCase() == r || ro.role.toLowerCase() == r.toString()) {
        return true
      }
    }
    return false;
  }

  getRoleActive(r) {
    r = r.toLowerCase();
    return this.role.toLowerCase() == r || this.role.toLowerCase() == r.toString()
  }
}
