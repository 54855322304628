import {Injectable} from '@angular/core';
import {User} from '../interface/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user = {
    id: '',
    name: '',
    role: '',
    avatar: ''
  };

  constructor(
  ) {
  }

  setUser(user: User) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }
}
