import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {IonicStorageModule} from '@ionic/storage-angular';
import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import {Device} from '@ionic-native/device/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {CurrencyMaskInputMode, NgxCurrencyModule} from "ngx-currency";
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {TabNavigationComponent} from "./components/v1-components/tab-navigation/tab-navigation.component";

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 0,
  prefix: "",
  suffix: " VND",
  thousands: ",",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
    declarations: [AppComponent, TabNavigationComponent],
    entryComponents: [],
    imports: [BrowserModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicStorageModule.forRoot(
            {
                name: '_DHLEE'
            }
        )],
    providers: [
        AndroidPermissions,
        Device,
        FCM,
        StatusBar,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    ],
    bootstrap: [AppComponent],
    exports: [
        TabNavigationComponent
    ]
})
export class AppModule {

}
