import axios from 'axios';
import {environment} from "../../environments/environment";


axios.interceptors.request.use((config) => {
  config.baseURL = environment.baseURL;
  // console.log('baseURL: ' + config.baseURL);
  return config;
}, (error) => Promise.reject(error));

export default axios;
