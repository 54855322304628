import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Platform} from "@ionic/angular";

@Component({
    selector: 'app-tab-navigation',
    templateUrl: './tab-navigation.component.html',
    styleUrls: ['./tab-navigation.component.scss'],
})
export class TabNavigationComponent implements OnInit {
    teacherIcon = "/assets/v1/icon/teacher.svg";
    teacherIconPink = "/assets/v1/icon/teacher-pink.svg";
    bookmarkIcon = "/assets/v1/icon/bookmark.svg";
    bookmarkIconPink = "/assets/v1/icon/bookmark-2.svg";
    userIcon = "/assets/v1/icon/user.svg";
    userIconPink = "/assets/v1/icon/profile.svg";
    logoDesktop = "/assets/v1/logo/logo DHLEE desktop.png";
    Active = '';
    @Input() slot: string = 'top';
    isDesktop: boolean;
    activeHome: boolean;

    constructor(private router: Router, private platform: Platform
    ) {
        this.isDesktop = platform.is('desktop')
    }

    checkActive(checkLink: string) {
        if (this.router.url) {
            let lengthUrl = this.router.url.length;
            if (this.router.url.substr((lengthUrl - 2), 2) === 'v1') {
                this.activeHome = true;
            } else {
                this.activeHome = false;
                return this.router.url.indexOf(checkLink) > 0
            }
        }
    }

    ngOnInit() {

    }
}
