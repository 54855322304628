import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./_guard/auth.guard";

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [AuthGuard],
        data: {
            title: 'DHLee | Trang chủ'
        }
    },
    {
        path: 'v1',
        loadChildren: () => import('./components/v1-components/v1.module').then(m => m.V1Module),
        data: {
            title: 'DHLee | Trang chủ'
        }
    },
    {
        path: 'dang-nhap',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        data: {
            title: 'DHLee | Đăng Nhập'
        }
    },
    {
        path: 'khach-hang',
        loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerPageModule),
        canActivate: [AuthGuard],
        data: {
            title: 'DHLee | Khách hàng'
        }
    },
    {
        path: 'khach-hang/tao-khach-hang',
        loadChildren: () => import('./pages/customer-create/customer-create.module').then(m => m.CustomerCreatePageModule),
        canActivate: [AuthGuard],
        data: {
            title: 'DHLee | Tạo khách hàng'
        }
    },
    {
        path: 'khach-hang/chinh-sua/:id',
        loadChildren: () => import('./pages/customer-edit/customer-edit.module').then(m => m.CustomerEditPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'khach-hang/:id',
        loadChildren: () => import('./pages/customer-detail/customer-detail.module').then(m => m.CustomerDetailPageModule),
        canActivate: [AuthGuard],
        data: {
            title: 'DHLee | Chi tiết khách hàng'
        }
    },
    {
        path: 'tai-lieu/tao-tai-lieu/:id',
        loadChildren: () => import('./pages/document-create/document-create.module').then(m => m.DocumentCreatePageModule),
        canActivate: [AuthGuard],
        data: {
            title: 'DHLee | Tạo tài liệu'
        }
    },
    {
        path: 'tai-lieu/:id',
        loadChildren: () => import('./pages/document-edit/document-edit.module').then(m => m.DocumentEditPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'nguoi-gioi-thieu',
        loadChildren: () => import('./pages/references/references.module').then(m => m.ReferencesPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'nguoi-gioi-thieu/tao-nguoi-gioi-thieu',
        loadChildren: () => import('./pages/references-create/references-create.module').then(m => m.ReferencesCreatePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'nguoi-gioi-thieu/:id',
        loadChildren: () => import('./pages/references-edit/references-edit.module').then(m => m.ReferencesEditPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'ghi-chu',
        loadChildren: () => import('./pages/note/note.module').then(m => m.NotePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'ghi-chu/tao-ghi-chu/:id',
        loadChildren: () => import('./pages/note-create/note-create.module').then(m => m.NoteCreatePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'ghi-chu/:id',
        loadChildren: () => import('./pages/note-edit/note-edit.module').then(m => m.NoteEditPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'thanh-toan-nguoi-gioi-thieu/:id',
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule)
    },
    {
        path: 'hop-dong/tao-hop-dong/:id',
        loadChildren: () => import('./pages/contract-create/contract-create.module').then(m => m.ContractCreatePageModule)
    },
    {
        path: 'hop-dong/chinh-sua-hop-dong/:id',
        loadChildren: () => import('./pages/contract-edit/contract-edit.module').then(m => m.ContractEditPageModule)
    },
    {
        path: 'hop-dong',
        loadChildren: () => import('./pages/contract/contract.module').then(m => m.ContractPageModule)
    },
    {
        path: 'hop-dong/:id',
        loadChildren: () => import('./pages/contract-detail/contract-detail.module').then(m => m.ContractDetailPageModule)
    },
    {
        path: 'thanh-toan-hop-dong/:id',
        loadChildren: () => import('./pages/contract-payment/contract-payment.module').then(m => m.ContractPaymentPageModule)
    },
    {
        path: 'thanh-toan-hop-dong/chi-tiet/:id',
        loadChildren: () => import('./pages/contract-payment-edit/contract-payment-edit.module').then(m => m.ContractPaymentEditPageModule)
    },
    {
        path: 'them-thanh-vien/:id',
        loadChildren: () => import('./pages/family-create/family-create.module').then(m => m.FamilyCreatePageModule)
    },
    {
        path: 'thanh-vien/:id',
        loadChildren: () => import('./pages/family-edit/family-edit.module').then(m => m.FamilyEditPageModule)
    },
    {
        path: 'audit-log',
        loadChildren: () => import('./pages/audit-log/audit-log.module').then(m => m.AuditLogPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
